/**
 * Sorts an array of objects by a passed property.
 *
 * @param items         - Array to sort.
 * @param sortBy        - Sort by property.
 * @param sortDirection - Sort direction (asc or desc)
 */
const sortLocaleString = (items, sortBy, sortDirection) => {
  if (!items?.length) {
    return [];
  }
  const sortByData = sortBy.split('.');
  return [...items].sort((first, second) => {
    let firstEl = {
      ...first
    };
    let secondEl = {
      ...second
    };
    if (sortByData.length > 1) {
      for (const el of sortByData) {
        firstEl = firstEl[el];
        secondEl = secondEl[el];
      }
    } else {
      firstEl = firstEl[sortBy];
      secondEl = secondEl[sortBy];
    }
    if (sortDirection === 'desc') {
      return compareLocaleStrings(secondEl, firstEl);
    }
    return compareLocaleStrings(firstEl, secondEl);
  });
};
const compareLocaleStrings = (a, b) => {
  return `${a}`.localeCompare(`${b}`);
};

/**
 * Save value to either sessionStorage or localStorage (if specified)
 *
 * @param key         - Name of item to save from storage
 * @param value       - Value to save
 * @param storageType - Type of storage to set
 */
const setStorageItem = (key, value, storageType = sessionStorage) => {
  storageType.setItem(key, encodeStorageItem(value));
};
/**
 * Get item from either sessionStorage or localStorage (if specified)
 *
 * @param key         - Name of item to retrieve from storage
 * @param storageType - Type of storage to look into
 */
const getStorageItem = (key, storageType = sessionStorage) => {
  const value = storageType.getItem(key);
  return value ? decodeStorageItem(value) : null;
};
/**
 * Checks whether an entry with the specified key exists in the storage.
 *
 * @param key         - Name to check for
 * @param storageType - Check for a specific storage
 */
const hasStorageItem = (key, storageType = sessionStorage) => {
  return !!storageType.getItem(key);
};
/**
 * Remove an item from storage.
 *
 * @param key         - Name of item to remove from storage
 * @param storageType - Type of storage to remove from
 */
const removeStorageItem = (key, storageType = sessionStorage) => {
  storageType.removeItem(key);
};
/**
 * Clears the storage by removing all entries.
 *
 * @param storageType - Type of storage to clear
 */
const clearStorage = (storageType = sessionStorage) => {
  storageType.clear();
};
/**
 * Encode value into a string.
 *
 * @param value - Value to decode
 */
const encodeStorageItem = value => {
  return typeof value === 'string' ? value : JSON.stringify(value);
};
/**
 * Decode value from a stringified value.
 *
 * @param value - Value to encode
 */
const decodeStorageItem = value => {
  try {
    return JSON.parse(value);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return value;
  }
};

/**
 * Copy the passed value to the device's clipboard.
 *
 * @param value - Value to copy
 */
const copyToClipboard = value => navigator.clipboard.writeText(value);

/**
 * Generated bundle index. Do not edit.
 */

export { clearStorage, compareLocaleStrings, copyToClipboard, decodeStorageItem, encodeStorageItem, getStorageItem, hasStorageItem, removeStorageItem, setStorageItem, sortLocaleString };
